import React, {
  createContext,
  ReactNode,
  FC,
  useEffect,
  useState,
  useContext
} from "react";
import { LinkInterceptor } from "./link-interceptor";
import { History } from "./history";
import { Match } from "./match";
import { Route } from "./route";

export type RouterContextValue = {
  url: URL;
  previousUrl: URL | null;
  transitionId: number;
  push: (href: string) => void;
  replace: (href: string) => void;
  back: () => void;
  match: <T extends {}>(route: Route<T>) => Match<T> | null;
};

export const RouterContext = createContext<RouterContextValue>({
  url: new URL(window.location.href),
  previousUrl: null,
  transitionId: 0,
  push() {},
  replace() {},
  back() {},
  match() {
    return null;
  }
});

type Props = {
  children: ReactNode;
};

export const Router: FC<Props> = ({ children }) => {
  const [history] = useState(() => new History());
  const [state, setState] = useState<{
    url: URL;
    previousUrl: URL | null;
    transitionId: number;
  }>(() => ({
    url: new URL(window.location.href),
    previousUrl: null,
    transitionId: 0
  }));

  useEffect(() => {
    history.onChange(action => {
      setState(state => ({
        url: new URL(window.location.href),
        previousUrl: state.url,
        transitionId:
          action === "replace" ? state.transitionId : state.transitionId + 1
      }));
    });

    return () => {
      history.dispose();
    };
  }, [history]);

  return (
    <RouterContext.Provider
      value={{
        url: state.url,
        previousUrl: state.previousUrl,
        transitionId: state.transitionId,
        push: history.push,
        replace: history.replace,
        back: history.back,
        match: route => route.match(state.url)
      }}
    >
      <LinkInterceptor>{children}</LinkInterceptor>
    </RouterContext.Provider>
  );
};

export const useRouter = () => useContext(RouterContext);
