import React, { FC, useEffect } from "react";
import { useRouter } from "./router";

const getInterceptableHref = (e: MouseEvent): string | undefined => {
  const target = e.target as HTMLElement;

  // Allow others to handle the click instead
  if (e.defaultPrevented) return;
  // Did we click on a link?
  const a = target.closest<HTMLAnchorElement>("a");
  if (!a) return;
  // Ignore non-primary mouse buttons
  if (e.button !== 0) return;
  // Ignore modifier keys
  if (e.ctrlKey || e.shiftKey || e.altKey || e.metaKey) return;
  // Does the link have an href?
  const href = a.getAttribute("href");
  if (href == null) return;
  // Ignore other targets
  if (a.target && a.target !== "_self") return;
  // Ignore contenteditables
  const contentEditable = target.closest<HTMLElement>("[contenteditable]");
  if (contentEditable && contentEditable.isContentEditable) return;

  return href;
};

/**
 * Intercepts link clicks and directs them through the router
 */
export const LinkInterceptor: FC = ({ children }) => {
  const { push } = useRouter();

  useEffect(() => {
    function onClick(e: MouseEvent) {
      const href = getInterceptableHref(e);
      if (href != null) {
        e.preventDefault();
        push(href);
      }
    }

    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("click", onClick);
    };
  }, [push]);

  return <>{children}</>;
};
