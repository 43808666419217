import React, { FC } from "react";
import { css } from "emotion";

const styles = css`
  display: grid;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Screen: FC = ({ children }) => {
  return <div className={styles}>{children}</div>;
};
