import { match, Match } from "./match";
import { compile, PathFunction } from "path-to-regexp";

class Paramless {
  private __paramless = null;
}

export type RouteProps<T> =
  | string
  | {
      path: string;
      search?: { [name: string]: string };
      condition?: (match: Match<T>) => boolean;
    };

export class Route<T extends {} = Paramless> {
  pathPattern: string;
  searchPatterns: { [name: string]: string };
  condition: (match: Match<T>) => boolean;
  private compiled: { [pattern: string]: PathFunction<object> } = {};

  constructor(props: RouteProps<T>) {
    const _props = typeof props === "string" ? { path: props } : props;
    this.pathPattern = _props.path;
    this.searchPatterns = _props.search || {};
    this.condition = _props.condition || (() => true);
  }

  toUrl(params: T extends Paramless ? void : T): string {
    if (!params) {
      return this.pathPattern;
    }
    const pathname = this.compile(this.pathPattern)(params as {});
    const queryStrings = [];
    for (let [name, pattern] of Object.entries(this.searchPatterns)) {
      const value = this.compile(pattern)(params as {});
      if (value) {
        queryStrings.push(
          `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
        );
      }
    }
    return pathname + (queryStrings.length ? "?" + queryStrings.join("&") : "");
  }

  match(urlLike: URL | string): Match<T> | null {
    const matchResult = match<T>(
      this.pathPattern,
      this.searchPatterns
    )(urlLike);

    if (!matchResult) {
      return null;
    }

    if (!this.condition(matchResult)) {
      return null;
    }

    return matchResult;
  }

  private compile(pattern: string): PathFunction<object> {
    if (!this.compiled[pattern]) {
      this.compiled[pattern] = compile(pattern);
    }
    return this.compiled[pattern];
  }
}
