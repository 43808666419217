export type HistoryAction = "push" | "pop" | "replace";

export type HistoryChangeListener = (action: HistoryAction) => void;

/**
 * Allows listening to history events, because pushState and replaceState
 * don't trigger browser events.
 */
export class History {
  private changeListeners: Set<HistoryChangeListener> = new Set();

  constructor() {
    window.addEventListener("popstate", this.onPopState);
  }

  private onPopState = () => {
    this.emitChange("pop");
  };

  private emitChange = (action: HistoryAction) => {
    this.changeListeners.forEach(listener => {
      listener(action);
    });
  };

  onChange = (listener: HistoryChangeListener) => {
    this.changeListeners.add(listener);
    return () => {
      this.changeListeners.delete(listener);
    };
  };

  push = (href: string) => {
    window.history.pushState({}, "", href);
    this.emitChange("push");
  };

  replace = (href: string) => {
    window.history.replaceState({}, "", href);
    this.emitChange("replace");
  };

  back = () => {
    window.history.back();
  };

  dispose = () => {
    window.removeEventListener("popstate", this.onPopState);
  };
}
