import { ReplaySubject } from "rxjs";
import { useObservable } from "./common/use-observable";

export type User = { accessToken: string };

export type Anonymous = "anonymous";

export const currentUser$ = new ReplaySubject<User | Anonymous>(1);

gapi.load("client", () => {
  gapi.client
    .init({
      apiKey: "AIzaSyAX13Xgmk-ZbdGJHj274M-MIeahSzHXFMA",
      clientId:
        "32182236973-7882rs12o9qfnn4m51bri563vqlf0qn0.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/photoslibrary",
    })
    .then(() => {
      const token = gapi.auth.getToken();
      if (token) {
        currentUser$.next({ accessToken: token.access_token });
      } else {
        currentUser$.next("anonymous");
      }
    });
});

export const signIn = (): void => {
  gapi.auth.signIn({
    clientid:
      "32182236973-7882rs12o9qfnn4m51bri563vqlf0qn0.apps.googleusercontent.com",
    scope: "https://www.googleapis.com/auth/photoslibrary",
    cookiepolicy: "none",
    callback() {
      const token = gapi.auth.getToken();
      if (token) {
        currentUser$.next({ accessToken: token.access_token });
      } else {
        currentUser$.next("anonymous");
      }
    },
  });
};

export const signOut = () => {
  gapi.auth.signOut();
};

export const useSignedInUser = (): User => {
  const currentUser = useObservable(currentUser$, []);

  if (currentUser === "anonymous") {
    throw new Error("Not signed in");
  }

  return currentUser;
};
