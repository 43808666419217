import { Observable } from "rxjs";
import { getLatestObservableValue } from "./get-latest-observable-value";
import { useState, useEffect, DependencyList } from "react";

export const useObservable = <T>(
  observable: Observable<T>,
  dependencies: DependencyList
) => {
  const value = getLatestObservableValue(observable);
  const [state, setState] = useState(value);
  useEffect(() => {
    const subscription = observable.subscribe(setState);
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
  return state;
};
