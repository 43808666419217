import { withRetries } from "./with-retries";

export const jsonFetch = <T>(
  url: string,
  requestInit?: RequestInit
): Promise<T> =>
  withRetries(
    () =>
      fetch(url, requestInit)
        .then((value) => {
          if (value.status !== 200) {
            throw value;
          }
          return value;
        })
        .then((value) => value.json()),
    0
  );
