import React, { FC } from "react";
import { css } from "emotion";
import { ROUTES } from "./routes";

const styles = css`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 18px;

  > a {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 12px;
  }
`;

export const Home: FC = () => {
  return (
    <div className={styles}>
      <a href={ROUTES.FLASH_CARDS_RANDOM.toUrl()}>Flash cards (random)</a>
      <a href={ROUTES.FLASH_CARDS_NEWEST.toUrl()}>Flash cards (newest)</a>
    </div>
  );
};
