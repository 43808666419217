import React, { FC } from "react";
import ReactDOM from "react-dom";
import { css } from "emotion";

const modalStyles = css`
  background: #222;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Modal: FC = ({ children }) => {
  return ReactDOM.createPortal(
    <div className={modalStyles}>{children}</div>,
    document.body
  );
};
