import { match as matchPath } from "path-to-regexp";
import { toUrlObject } from "./to-url-object";

export type Match<T extends {}> = {
  params: T;
};

export const match = <T extends {}>(
  pathPattern: string,
  searchPatterns: { [name: string]: string }
) => {
  return (urlLike: URL | string): Match<T> | null => {
    const url = toUrlObject(urlLike);
    if (url.origin !== window.location.origin) return null;
    if (pathPattern[pathPattern.length - 1] === "/") {
      pathPattern = pathPattern.substr(0, pathPattern.length - 1);
    }

    const params = {};

    // Match path
    if (pathPattern !== "*") {
      const pathMatcher = matchPath(pathPattern, {
        end: true,
        decode: decodeURIComponent
      });
      const pathResult = pathMatcher(url.pathname);
      if (!pathResult) return null;
      Object.assign(params, pathResult.params);
    }

    // Match query
    for (let [name, pattern] of Object.entries(searchPatterns)) {
      const queryMatcher = matchPath(pattern, {
        end: true,
        decode: decodeURIComponent,
        delimiter: "&"
      });
      const queryResult = queryMatcher(url.searchParams.get(name) || "");
      if (!queryResult) {
        return null;
      }
      Object.assign(params, queryResult.params);
    }

    return { params: params as T };
  };
};
