import React from "react";
import { Authentication } from "./authentication";
import { FlashCards } from "./flash-cards";
import { Home } from "./home";
import { Screen } from "./screen";
import { Loading } from "./loading";
import { Router, useRouter } from "./router/router";
import { ROUTES } from "./routes";

const Screens = () => {
  const router = useRouter();
  if (router.match(ROUTES.FLASH_CARDS_RANDOM)) {
    return <FlashCards order="random" />;
  }
  if (router.match(ROUTES.FLASH_CARDS_NEWEST)) {
    return <FlashCards order="newest" />;
  }
  return <Home />;
};

export function App() {
  return (
    <Screen>
      <React.Suspense fallback={<Loading />}>
        <Router>
          <Authentication>
            <Screens />
          </Authentication>
        </Router>
      </React.Suspense>
    </Screen>
  );
}
