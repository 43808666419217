import React, { FC } from "react";
import { useObservable } from "./common/use-observable";
import { currentUser$, signIn } from "./current-user";

export const Authentication: FC = ({ children }) => {
  const currentUser = useObservable(currentUser$, []);

  if (currentUser === "anonymous") {
    return (
      <div>
        <button onClick={signIn}>Sign in</button>
      </div>
    );
  }

  return <>{children}</>;
};
