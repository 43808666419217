import React, { FC, useEffect, useState } from "react";
import { jsonFetch } from "./common/json-fetch";
import { useSignedInUser } from "./current-user";
import Carousel from "react-images";
import { Modal } from "./modal";
import { Loading } from "./loading";

type MediaItemResponse = {
  id: string;
  productUrl: string;
  baseUrl: string;
  mimeType: string;
  filename: string;
  mediaMetadata: {
    creationTime: string;
    width: string;
    height: string;
  };
};

type MediaForAlbumResponse = {
  mediaItems: MediaItemResponse[];
  nextPageToken: string;
};

function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
const albumIds = [
  "AAVD89ufVENyihzbI4tS6zSOD7GXsdJTBGHleB-rQwNcdN_YwHYFviBNo_iipTMa-AUaFr6occtP",
  "AKcj-V5zS6uAwcn25r8goE3AhY7vCH08WGsv81NIUPt2kSzdTIwmM8i-m4eNxp0HXGHz2QidhQKX1lhX5bWh7gg7_eOPfJ4Ucw",
];

export const FlashCards: FC<{ order: "random" | "newest" }> = ({ order }) => {
  const user = useSignedInUser();
  const [mediaUrls, setMediaUrls] = useState<string[] | undefined>();

  useEffect(() => {
    jsonFetch<MediaForAlbumResponse>(
      "https://photoslibrary.googleapis.com/v1/mediaItems:search",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          albumId: albumIds[0],
        }),
      }
    )
      .catch((response) => {
        if (response.status === 400) {
          return jsonFetch<MediaForAlbumResponse>(
            "https://photoslibrary.googleapis.com/v1/mediaItems:search",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${user.accessToken}`,
              },
              body: JSON.stringify({
                albumId: albumIds[1],
              }),
            }
          );
        }
        throw response;
      })
      .then((data) => {
        const urls = data.mediaItems
          .sort((a, b) =>
            b.mediaMetadata.creationTime.localeCompare(
              a.mediaMetadata.creationTime
            )
          )
          .map((mediaItem) => mediaItem.baseUrl + `=w1024-h1024`);
        setMediaUrls(order === "random" ? shuffle(urls) : urls);
      });
  }, [user.accessToken, order]);

  return mediaUrls ? (
    <Modal>
      <Carousel
        views={mediaUrls.map((mediaUrl) => ({ source: mediaUrl }))}
        components={{ FooterCaption: () => null }}
      />
    </Modal>
  ) : (
    <Loading />
  );
};
